<!-- eslint-disable vue/html-closing-bracket-newline -->
<template>
  <div v-if="permission.access">
    <div class="row">
      <div class="col-md-8">
        <b-card no-body>
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <!-- :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" -->
                <v-select
                  @on-change="paginateChange"
                  :options="perPageOptions"
                  v-model="search.paginate"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="search.search_data"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Sl</th>
                  <th class="text-center">Icon</th>
                  <th>Name</th>
                  <th class="text-center">Status</th>
                  <th class="text-center" v-if="permission.edit">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(department, index) in departments.data"
                  :key="index"
                  v-if="departments.data.length"
                >
                  <td class="align-middle text-center">
                    {{ departments.from + index }}
                  </td>
                  <td class="align-middle text-center">
                    <img
                      :src="$store.state.base_url + department.icon_image"
                      alt=""
                    />
                  </td>
                  <td>
                    <p class="mb-0"><b>BN : </b>{{ department.name_bn }}</p>
                    <p class="mb-0"><b>EN : </b>{{ department.name_en }}</p>
                  </td>
                  <td class="align-middle text-center">
                    <span
                      class="badge"
                      :class="
                        department.status == 1
                          ? 'badge-success'
                          : 'badge-danger'
                      "
                    >
                      {{ department.status == 1 ? "Active" : "Inactive" }}
                    </span>
                  </td>
                  <td class="align-middle text-center" v-if="permission.edit">
                    <button
                      class="btn btn-primary btn-sm"
                      @click="edit(department.id)"
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ departments.from ? departments.from : 0 }} to
                  {{ departments.to ? departments.to : 0 }} of
                  {{ departments.total ? departments.total : 0 }}
                  entries</span
                >
              </b-col>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <pagination
                  :data="departments"
                  @pagination-change-page="getResults"
                  align="right"
                  :show-disabled="true"
                  :limit="5"
                ></pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>
      <div class="col-md-4" v-if="permission.create || form.id != ''">
        <b-card :title="(form.id == '' ? 'Create' : 'Update') + ' Department'">
          <b-card-text
            ><validation-observer ref="loginValidation">
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent
                enctype="multipart/form-data"
              >
                <div class="row">
                  <div class="col-md-12">
                    <!-- email -->
                    <b-form-group label="Name Bangla" label-for="name_bn">
                      <validation-provider
                        #default="{ errors }"
                        name="name_bn"
                        rules="required"
                      >
                        <b-form-input
                          id="name_bn"
                          v-model="form.name_bn"
                          :state="errors.length > 0 ? false : null"
                          name="name_bn"
                          placeholder="Enter Your Name Bangla"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <!-- email -->
                    <b-form-group label="Name English" label-for="name_en">
                      <validation-provider
                        #default="{ errors }"
                        name="name_en"
                        rules="required"
                      >
                        <b-form-input
                          id="name_en"
                          v-model="form.name_en"
                          :state="errors.length > 0 ? false : null"
                          name="name_en"
                          placeholder="Enter Your Name English"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <!-- email -->
                    <b-form-group label="Meta Title" label-for="meta_title">
                      <validation-provider
                        #default="{ errors }"
                        name="meta_title"
                        rules="required"
                      >
                        <b-form-input
                          id="meta_title"
                          v-model="form.meta_title"
                          :state="errors.length > 0 ? false : null"
                          name="meta_title"
                          placeholder="Enter Your Meta Title"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <b-form-group
                      label="Meta Description"
                      label-for="meta_description"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="meta_description"
                        rules="required"
                      >
                        <b-form-textarea
                          id="meta_description"
                          v-model="form.meta_description"
                          :state="errors.length > 0 ? false : null"
                          name="meta_description"
                          placeholder="Enter Your Meta Description"
                          rows="4"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <div class="border rounded p-2 mb-2">
                      <b-media
                        no-body
                        vertical-align="center"
                        class="flex-column flex-md-row"
                      >
                        <b-media-aside>
                          <b-img
                            :src="icon_image"
                            height="36"
                            width="36"
                            class="rounded mr-2 mb-1 mb-md-0"
                          />
                        </b-media-aside>
                        <b-media-body>
                          <b-form-file
                            id="icon_image"
                            accept=".jpg, .png, .gif"
                            @change="loadImage($event)"
                            placeholder="Select Icon Image"
                            drop-placeholder="Drop file here..."
                          />
                        </b-media-body>
                      </b-media>
                    </div>
                  </div>
                </div>
                <!-- submit buttons -->
                <b-form-checkbox
                  name="status"
                  style="margin-top: 5px"
                  v-model="form.status"
                  switch
                  inline
                >
                  Status
                </b-form-checkbox>
                <b-form-checkbox
                  name="is_show"
                  style="margin-top: 5px"
                  v-model="form.is_show"
                  switch
                  inline
                >
                  Is Show
                </b-form-checkbox>
                <b-button
                  type="submit"
                  class="float-right"
                  variant="primary"
                  @click="validationForm"
                >
                  {{ form.id == "" ? "Create" : "Update" }}
                </b-button>
                <b-button
                  type="submit"
                  class="float-right mx-1"
                  variant="outline-primary"
                  @click="Close"
                >
                  Close
                </b-button>
              </b-form>
            </validation-observer></b-card-text
          >
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import _ from "lodash";
export default {
  data() {
    return {
      search: {
        search_data: "",
        paginate: 10,
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100],
      form: {
        id: "",
        name_en: "",
        name_bn: "",
        meta_title: "",
        meta_description: "",
        status: true,
        is_show: true,
        icon_image: "",
      },
      icon_image: this.$store.state.base_url + "images/department/default.png",
      departments: {},
      permission: {
        access: false,
        create: false,
        edit: false,
        delete: false,
      },
    };
  },
  mounted() {
    this.getResults();
    this.Permission();
  },
  methods: {
    async getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      const departments = await this.callApi(
        "post",
        "/app/course/department?page=" + this.search.page,
        this.search
      );
      if (departments.status == 200) {
        this.departments = departments.data.departments;
      }
    },

    loadImage(e) {
      this.form.icon_image = e.target.files[0];
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.icon_image = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    Close() {
      this.form.id = "";
      this.form.name_bn = "";
      this.form.name_en = "";
      this.form.meta_title = "";
      this.form.meta_description = "";
      this.form.status = true;
      this.form.is_show = true;
      this.form.icon_image = "";
      this.icon_image =
        this.$store.state.base_url + "images/department/default.png";
    },

    async edit(id) {
      const department = await this.callApi(
        "post",
        "/app/course/department/show/" + id
      );
      if (department.status == 200) {
        this.form.id = department.data.department.id;
        this.form.name_bn = department.data.department.name_bn;
        this.form.name_en = department.data.department.name_en;
        this.form.meta_title = department.data.department.meta_title;
        this.form.meta_description =
          department.data.department.meta_description;
        this.form.status =
          department.data.department.status == true ? true : false;
        this.form.is_show =
          department.data.department.is_show == true ? true : false;
        this.icon_image =
          this.$store.state.base_url + department.data.department.icon_image;
      }
    },

    add() {
      const fd = new FormData();
      if (this.form.icon_image) {
        fd.append(
          "icon_image",
          this.form.icon_image,
          this.form.icon_image.name
        );
      }
      fd.append("id", this.form.id);
      fd.append("name_en", this.form.name_en);
      fd.append("name_bn", this.form.name_bn);
      fd.append("meta_title", this.form.meta_title);
      fd.append("meta_description", this.form.meta_description);
      fd.append("status", this.form.status);
      fd.append("is_show", this.form.is_show);

      axios
        .post("/app/course/department/add", fd)
        .then((res) => {
          this.getResults();
          this.Close();
          this.s(res.data.message);
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.icon_image)
              this.e(e.response.data.errors.icon_image[0]);
            if (e.response.data.errors.name_en)
              this.e(e.response.data.errors.name_en[0]);
            if (e.response.data.errors.name_bn)
              this.e(e.response.data.errors.name_bn[0]);
            if (e.response.data.errors.meta_title)
              this.e(e.response.data.errors.meta_title[0]);
            if (e.response.data.errors.meta_description)
              this.e(e.response.data.errors.meta_description[0]);
            if (e.response.data.errors.status)
              this.e(e.response.data.errors.status[0]);
            if (e.response.data.errors.is_show)
              this.e(e.response.data.errors.is_show[0]);
          }
        });
    },

    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.add();
        }
      });
    },

    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),

    // Paginate Data Gate
    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },

    async Permission() {
      const permissions = await this.callApi("post", "/app/permission");
      permissions.data.permissions.forEach((permission) => {
        if (permission.slug == "course.department.access") {
          this.permission.access = true;
        }

        if (permission.slug == "course.department.create") {
          this.permission.create = true;
        }

        if (permission.slug == "course.department.edit") {
          this.permission.edit = true;
        }

        if (permission.slug == "course.department.destroy") {
          this.permission.delete = true;
        }
      });
    },
  },
  watch: {
    "search.search_data": function (val) {
      this.SearchData();
    },
    "search.paginate": function (val) {
      this.paginateChange();
    },
  },
  components: {
    vSelect,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
  },
};
</script>

<style></style>
